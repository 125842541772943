<template>
  <v-card class="pl-2 pr-4 pt-0">
    <v-container class="pt-0 px-0">
      <v-form
        v-model="isFormValid2"
        form="form-adhesiones"
        ref="form-adhesiones"
        id="form-adhesiones"
        @submit.prevent="saveConfig()"
      >
        <v-card-title class="py-1">
          <span class="pl-1 primary--text">{{ formTitle }}</span>
        </v-card-title>
        <v-row>
          <v-col cols="6" class="py-0 px-8">
            <v-text-field
              v-model="nombreConfiguracion"
              dense
              outlined
              id="nombreConfiguracionAdhesiones"
              ref="nombreConfiguracionAdhesiones"
              tabindex="1"
              :rules="
                rules.required.concat(rules.maxLength(nombreConfiguracion, 100))
              "
              hide-details="auto"
              label="Nombre"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4" class="py-0">
            <v-select
              v-model="plantillaProcesoSelected"
              :items="plantillasItems"
              item-text="value"
              tabindex="2"
              item-value="id"
              hide-details="auto"
              :rules="rules.required"
              label="Adhesión"
              outlined
              required
              @change="setTiposAdhesionesYTableVariables()"
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-card-text class="pb-0 pt-1">
          <v-card>
            <v-data-table
              :headers="headers"
              :items="configuracionesItems"
              id="data-table-configuraciones-adhesiones"
              ref="data-table-configuraciones-adhesiones"
              dense
              :items-per-page="50"
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      id="edit-icon-data-table-configuraciones-adhesiones"
                      ref="edit-icon-data-table-configuraciones-adhesiones"
                      v-on="on"
                      @click="editItem(item)"
                    >
                      {{ editIcon }}
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      id="delete-icon-data-table-configuraciones-adhesiones"
                      ref="delete-icon-data-table-configuraciones-adhesiones"
                      v-on="on"
                      :disabled="isEdit"
                      @click="deleteItem(item)"
                    >
                      {{ deleteIcon }}
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
              <template v-slot:top>
                <v-form
                  v-model="isFormValid"
                  ref="form-configuraciones-adhesiones"
                  form="form-configuraciones-adhesiones"
                  id="form-configuraciones-adhesiones"
                  @submit.prevent="addConfiguracion()"
                >
                  <v-row>
                    <v-col cols="12" md="12" class="py-1">
                      <span class="primary--text font-weight-medium py-0 pl-2"
                        >Configuraciones</span
                      >
                    </v-col>

                    <v-col cols="12" md="3" sm="12" class="py-0 pr-1 pl-5">
                      <v-autocomplete
                        label="Tipo adhesión"
                        :items="tiposAdhesiones"
                        item-text="value"
                        id="tipo-adhesion-configuraciones-adhesiones"
                        ref="tipo-adhesion-configuraciones-adhesiones"
                        item-value="id"
                        hide-details="auto"
                        tabindex="3"
                        return-object
                        v-model="tipoAdhesionSelected"
                        :rules="rules.required"
                        outlined
                        clearable
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" sm="12" class="py-0 pr-1 pl-3">
                      <v-autocomplete
                        label="Entidad financiera"
                        :items="entidadesFinancieras"
                        item-text="value"
                        item-value="id"
                        id="ent-fin-configuraciones-adhesiones"
                        ref="ent-fin-configuraciones-adhesiones"
                        tabindex="4"
                        return-object
                        v-model="entidadFinancieraSelected"
                        hide-details="auto"
                        :rules="
                          entidadFinancieraSelected != 0 ? rules.required : []
                        "
                        outlined
                        clearable
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" sm="12" class="py-0 pr-1 pl-3">
                      <v-autocomplete
                        label="Forma de pago"
                        :items="formasPago"
                        item-text="value"
                        item-value="id"
                        id="forma-pago-configuraciones-adhesiones"
                        ref="forma-pago-configuraciones-adhesiones"
                        tabindex="5"
                        return-object
                        v-model="formaPagoSelected"
                        hide-details="auto"
                        :rules="rules.required"
                        outlined
                        clearable
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      sm="12"
                      class="text-right pl-0 pt-1 pb-0 pr-5"
                    >
                      <v-btn
                        type="submit"
                        :disabled="!isFormValid"
                        id="btn-add-configuraciones-adhesiones"
                        ref="btn-add-configuraciones-adhesiones"
                        tabindex="6"
                        form="form-configuraciones-adhesiones"
                        color="primary"
                      >
                        {{ isEdit ? "Actualizar" : "Agregar" }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </template>
            </v-data-table>
          </v-card>

          <v-row>
            <v-col cols="12" md="12" class="pt-1 pb-0">
              <v-card>
                <v-data-table
                  outlined
                  dense
                  hide-default-footer
                  :header-props="{ sortIcon: null }"
                  id="data-table-datos-adhesiones"
                  ref="data-table-datos-adhesiones"
                  :items-per-page="50"
                  :headers="configs"
                  item-key="orden"
                  class="elevation-1"
                  :items="detalleConfig"
                  fixed-header
                >
                  <template v-slot:top>
                    <v-row>
                      <v-col cols="12" md="12" class="pb-0 pt-2">
                        <span class="primary--text py-0 pl-2 font-weight-medium"
                          >Datos adicionales</span
                        ></v-col
                      >
                    </v-row>
                  </template>
                  <template v-slot:[`item.valor`]="{ item }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          {{ item != undefined ? item.valor : "" }}
                          <v-icon
                            :color="'primary'"
                            small
                            tabindex="7"
                            id="edit-icon-datos-adhesiones"
                            ref="edit-icon-datos-adhesiones"
                            @click="openModal(item)"
                            >{{ editIcon }}</v-icon
                          >
                        </div>
                      </template>
                      <span>Editar valor</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card>

              <v-dialog
                v-if="openModalEditValor"
                v-model="openModalEditValor"
                max-width="35rem"
                @keydown.enter="saveEditValor()"
                persistent
                @keydown.esc="cancelEditValor()"
              >
                <v-card>
                  <v-container>
                    <v-card-title class="headline primary--text pt-0 pb-2">
                      Editar {{ nombreCampoToEdit }}
                    </v-card-title>
                    <v-card-text class="py-0">
                      <v-text-field
                        ref="edit-valor-config-adhesiones"
                        id="edit-valor-config-adhesiones"
                        v-model.trim="campoValorToEdit"
                        tabindex="8"
                        outlined
                        dense
                        :label="nombreCampoToEdit"
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions class="pt-0">
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        tabindex="9"
                        id="btn-cancelar-datos-adhesiones"
                        ref="btn-cancelar-datos-adhesiones"
                        @click="cancelEditValor()"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="primary"
                        tabindex="10"
                        id="btn-guardar-datos-adhesiones"
                        :disabled="
                          campoValorToEdit == null || campoValorToEdit == ''
                        "
                        ref="btn-guardar-datos-adhesiones"
                        @click="saveEditValor()"
                      >
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-0 pr-4">
          <v-btn
            outlined
            id="btn-cancelar-dialog-adhesiones"
            tabindex="10"
            ref="btn-cancelar-dialog-adhesiones"
            @click="closeModal()"
            >Cancelar</v-btn
          >
          <v-btn
            type="submit"
            :disabled="!isFormValid2"
            id="btn-guardar-dialog-adhesiones"
            ref="btn-guardar-dialog-adhesiones"
            tabindex="11"
            form="form-adhesiones"
            color="primary"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  props: {
    procesoAdhesionId: {
      type: Number,
      required: false,
      default: null
    }
  },
  directives: { mask },
  name: "EditConfigAdhesionesEntidadesCobranza",
  data: vm => ({
    deleteIcon: enums.icons.DELETE,
    editIcon: enums.icons.EDIT,
    rules: rules,
    tiposAdhesiones: [],
    entidadesFinancieras: [],
    formasPago: [],
    formaPagoSelected: null,
    configuracionesItems: [],
    isFormValid: false,
    isFormValid2: false,
    nombreConfiguracion: null,
    plantillaProcesoSelected: null,
    plantillasItems: [],
    formTitle: "Editar configuración",
    tipoAdhesionSelected: null,
    entidadFinancieraSelected: null,
    medioDePagoSelected: null,
    isEdit: false,
    editedIndex: -1,
    openModalEditValor: false,
    campoValorToEdit: null,
    nombreCampoToEdit: null,
    headers: [
      {
        text: "Tipo adhesión",
        sortable: false,
        align: "start",
        value: "plantillaProcesoTipoAdhesionNom"
      },
      {
        text: "Entidad financiera",
        sortable: false,
        align: "start",
        value: "entFinNom"
      },
      {
        text: "Forma de pago",
        sortable: false,
        align: "start",
        value: "fPagoNom"
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "end"
      }
    ],
    configs: [
      {
        text: "Orden",
        align: "start",
        value: "orden",
        sortable: false
      },
      { text: "Descripción", value: "descripcion", sortable: false },
      { text: "Ejemplo", value: "valorEjemplo", sortable: false },
      { text: "Valor", value: "valor", sortable: false }
    ],
    detalleConfig: [],
    idToEdit: null,
    variablesToSend: []
  }),
  created() {
    if (this.procesoAdhesionId != null) {
      let header = this.configs.indexOf(
        this.configs.find(x => x.value === "valorEjemplo")
      );
      this.configs.splice(header, 1);
      this.setConfig();
    } else {
      this.newConfig();
    }
    this.setSelects();
  },

  methods: {
    ...mapActions({
      getPlantillasProcesosTiposAdhesiones:
        "configuracion/getPlantillasProcesosTiposAdhesiones",
      getEntidadesFinancieras: "configAppBenef/getEntidadesFinancieras",
      getTiposAdhesion: "configuracion/getTiposAdhesion",
      getProcesoAdhesionConfigById:
        "configuracion/getProcesoAdhesionConfigById",
      getPlantillaProcesosDeta: "configuracion/getPlantillaProcesosDeta",
      getFormasDePago: "devengamientos/getFormasDePago",
      saveProcesoAdhesionConfig: "configuracion/saveProcesoAdhesionConfig",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      this.plantillasItems = await this.getPlantillasProcesosTiposAdhesiones();
      if (this.plantillasItems.length == 1 && this.procesoAdhesionId == null) {
        this.plantillaProcesoSelected = this.plantillasItems[0].id;
        this.setTiposAdhesionesYTableVariables();
      } 
      this.entidadesFinancieras = await this.getEntidadesFinancieras();
      if (this.entidadesFinancieras.length == 1) {
        this.entidadFinancieraSelected = this.entidadesFinancieras[0];
      }

      this.formasPago = await this.getFormasDePago();

      this.$nextTick(() => {
        this.$refs.nombreConfiguracionAdhesiones.focus();
      });
    },
    async setTiposAdhesionesYTableVariables() {
      if (this.plantillaProcesoSelected != null) {
        this.detalleConfig = await this.getPlantillaProcesosDeta(
          this.plantillaProcesoSelected
        );
        this.tiposAdhesiones = await this.getTiposAdhesion(
          this.plantillaProcesoSelected
        );
      }
    },
    addConfiguracion() {
      var registroDuplicado = this.configuracionesItems.some(
        (x, index) =>
          x.plantillaProcesoTipoAdhesionId === this.tipoAdhesionSelected.id &&
          x.entFinId === this.entidadFinancieraSelected.id &&
          x.fPagoId === this.formaPagoSelected.id &&
          index !== this.editedIndex
      );
      if (registroDuplicado) {
        this.setAlert({ type: "error", message: "Registro duplicado" });
        return;
      }
      const nuevaConfig = {
        plantillaProcesoTipoAdhesionNom: this.tipoAdhesionSelected.value,
        plantillaProcesoTipoAdhesionId: this.tipoAdhesionSelected.id,
        entFinNom: this.entidadFinancieraSelected.value,
        entFinId: this.entidadFinancieraSelected.id,
        fPagoNom: this.formaPagoSelected.value,
        fPagoId: this.formaPagoSelected.id
      };
      if (this.isEdit) {
        this.$set(this.configuracionesItems, this.editedIndex, {
          plantillaProcesoTipoAdhesionNom: this.tipoAdhesionSelected.value,
          plantillaProcesoTipoAdhesionId: this.tipoAdhesionSelected.id,
          entFinNom: this.entidadFinancieraSelected.value,
          entFinId: this.entidadFinancieraSelected.id,
          fPagoNom: this.formaPagoSelected.value,
          fPagoId: this.formaPagoSelected.id
        });
        this.setAlert({ type: "success", message: "Actualizado con éxito" });
      } else {
        this.configuracionesItems.push(nuevaConfig);
        this.setAlert({ type: "success", message: "Agregado con éxito" });
      }

      this.resetForm();
      this.$nextTick(() => {
        this.$refs["tipo-adhesion-configuraciones-adhesiones"].focus();
      });
    },
    editItem(item) {
      this.editedIndex = this.configuracionesItems.indexOf(item);
      this.tipoAdhesionSelected = this.tiposAdhesiones.find(
        t => t.id === item.plantillaProcesoTipoAdhesionId
      );
      this.entidadFinancieraSelected = this.entidadesFinancieras.find(
        e => e.id === item.entFinId
      );
      this.formaPagoSelected = this.formasPago.find(f => f.id === item.fPagoId);
      this.isEdit = true;
    },
    deleteItem(item) {
      const index = this.configuracionesItems.indexOf(item);
      if (index > -1) {
        this.configuracionesItems.splice(index, 1);
      }
    },
    resetForm() {
      this.$refs["form-configuraciones-adhesiones"].reset();
      //   this.tipoAdhesionSelected = null;
      //   this.entidadFinancieraSelected = null;
      //   this.formaPagoSelected = null;
      this.isEdit = false;
      this.editedIndex = -1;
    },
    openModal(item) {
      this.openModalEditValor = true;
      this.campoValorToEdit = item.valor;
      this.previousValue = item.valor;
      this.nombreCampoToEdit = item.descripcion;
      this.idToEdit = item.orden;
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs["edit-valor-config-adhesiones"].focus();
        });
      }, 250);
    },
    cancelEditValor() {
      const indice = this.detalleConfig.findIndex(
        x => x.orden === this.idToEdit
      );
      const result = this.detalleConfig.find(x => x.orden === this.idToEdit);
      result["valor"] = this.previousValue;
      this.detalleConfig.splice(indice, 1, result);
      this.openModalEditValor = false;
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs["edit-icon-datos-adhesiones"].$el.focus();
        });
      }, 500);
    },
    saveEditValor() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const indice = this.detalleConfig.findIndex(
          x => x.orden === this.idToEdit
        );
        const result = this.detalleConfig.find(x => x.orden === this.idToEdit);
        result["valor"] = this.campoValorToEdit;
        this.detalleConfig.splice(indice, 1, result);
        this.openModalEditValor = false;
        this.setAlert({ type: "success", message: "Actualizado con éxito" });
      }, 100);
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs["edit-icon-datos-adhesiones"].$el.focus();
        });
      }, 500);
    },
    async saveConfig() {
      let datosIncompletos = this.detalleConfig.some(x => x.valor === null);
      if (datosIncompletos) {
        this.setAlert({
          type: "warning",
          message: "Se deben configurar todos los datos adicionales."
        });
        return;
      }
      if (this.configuracionesItems.length === 0) {
        this.setAlert({
          type: "warning",
          message:
            "Se debe agregar al menos una configuración según Tipo adhesión, Entidad financiera y Forma de pago."
        });
        return;
      }
      const data = {
        procesoAdhesionId: this.procesoAdhesionId ?? null,
        plantillaProcesoId: this.plantillaProcesoSelected,
        nombre: this.nombreConfiguracion,
        procesoAdhesionConfigs: this.configuracionesItems,
        procesoAdhesionDetas: this.detalleConfig
      };
      const response = await this.saveProcesoAdhesionConfig(data);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    async setConfig() {
      const response = await this.getProcesoAdhesionConfigById(
        this.procesoAdhesionId
      );

      this.configuracionesItems = response.procesoAdhesionConfigs;
      this.detalleConfig = response.procesoAdhesionDetas;
      this.plantillaProcesoSelected = response.plantillaProcesoId;
      this.tiposAdhesiones = await this.getTiposAdhesion(
        this.plantillaProcesoSelected
      );
      this.nombreConfiguracion = response.nombre;
    },
    async newConfig() {
      this.formTitle = "Nueva configuración";
    },
    closeModal() {
      this.$emit("closeModal");
    }
  }
};
</script>
<style scoped></style>
