var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{staticClass:"pb-3",attrs:{"title":_vm.title}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.configuracionesItems,"item-key":"procesoAdhesionId","dense":"","search":_vm.search,"loading":_vm.isLoading,"show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{staticClass:"pt-5"},[_c('v-col',{staticClass:"pl-5",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.plantillasItems,"item-text":"value","item-value":"id","hide-details":"auto","label":"Adhesión","outlined":"","required":"","dense":""},on:{"change":function($event){return _vm.setProcesosAdhesionConfigs()}},model:{value:(_vm.plantillaProcesoSelected),callback:function ($$v) {_vm.plantillaProcesoSelected=$$v},expression:"plantillaProcesoSelected"}})],1),_c('v-col',{staticClass:"pt-4",attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.canCreate)?_c('v-col',{attrs:{"cols":"2","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openModal()}}},[_vm._v(" Nuevo ")])],1):_vm._e()],1)],1)]},proxy:true},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.handleExpansion(item, isExpanded)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(isExpanded ? "mdi-account-check" : "mdi-account"))])]}}],null,true)},[_c('span',[_vm._v("Usuarios")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v("Fecha alta:")]),_vm._v(" "+_vm._s(item.fecAlta)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v("Usuario alta:")]),_vm._v(" "+_vm._s(item.usuAlta)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v("Fecha modificación:")]),_vm._v(" "+_vm._s(item.fecModi)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v("Usuario modificación:")]),_vm._v(" "+_vm._s(item.usuModi)+" ")])],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEdit)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar configuración")])]):_vm._e(),(_vm.canDelete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.deleteConfig(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar configuración")])]):_vm._e()]}}],null,true)}),(_vm.openModalEditConfigAdhesion)?_c('v-dialog',{attrs:{"max-width":"70rem","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReload($event)}},model:{value:(_vm.openModalEditConfigAdhesion),callback:function ($$v) {_vm.openModalEditConfigAdhesion=$$v},expression:"openModalEditConfigAdhesion"}},[_c('EditConfigAdhesionesEntidadesCobranza',{attrs:{"procesoAdhesionId":_vm.procesoAdhesionId},on:{"closeModal":_vm.closeAndReload}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}})],1),_c('v-col',{staticClass:"to-right pt-7 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":function($event){return _vm.toggleHelp()},"toggleIcon":function($event){return _vm.toggleIcon()}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }