<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-data-table
          :headers="headers"
          :items="configuracionesItems"
          item-key="procesoAdhesionId"
          class="elevation-1"
          dense
          :search="search"
          :loading="isLoading"
          show-expand
          :expanded.sync="expanded"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row class="pt-5">
                <v-col cols="3" class="pl-5">
                  <v-select
                    v-model="plantillaProcesoSelected"
                    :items="plantillasItems"
                    item-text="value"
                    item-value="id"
                    hide-details="auto"
                    label="Adhesión"
                    outlined
                    required
                    @change="setProcesosAdhesionConfigs()"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="7" class="pt-4">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="handleExpansion(item, isExpanded)"
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  >{{
                    isExpanded ? "mdi-account-check" : "mdi-account"
                  }}</v-icon
                >
              </template>
              <span>Usuarios</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row>
                <v-col cols="12" md="3">
                  <strong>Fecha alta:</strong>
                  {{ item.fecAlta }}
                </v-col>
                <v-col cols="12" md="3">
                  <strong>Usuario alta:</strong>
                  {{ item.usuAlta }}
                </v-col>
                <v-col cols="12" md="3">
                  <strong>Fecha modificación:</strong>
                  {{ item.fecModi }}
                </v-col>
                <v-col cols="12" md="3">
                  <strong>Usuario modificación:</strong>
                  {{ item.usuModi }}
                </v-col>
              </v-row>
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar configuración</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteConfig(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar configuración</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <v-dialog
          v-if="openModalEditConfigAdhesion"
          v-model="openModalEditConfigAdhesion"
          max-width="70rem"
          @keydown.esc="closeAndReload"
          persistent
        >
          <EditConfigAdhesionesEntidadesCobranza
            :procesoAdhesionId="procesoAdhesionId"
            @closeModal="closeAndReload"
          />
        </v-dialog>
        <DeleteDialog
          :titleProp="titleDelete"
          :openDelete.sync="showDeleteModal"
          @onDeleteItem="confirmDelete()"
        />
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EditConfigAdhesionesEntidadesCobranza from "@/views/modules/cuotas/configuracion/EditConfigAdhesionesEntidadesCobranza";
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    Ayuda,
    EditConfigAdhesionesEntidadesCobranza
  },
  name: "ConfiguracionAdhesionesEntidadesCobranza",
  data() {
    return {
      optionCode: enums.webSiteOptions.ADHESIONES_ENTIDADES_COBRANZA,
      title: enums.titles.ADHESIONES_ENTIDADES_COBRANZA,
      showExpand: false,
      showHelp: false,
      procesoAdhesionId: null,
      showIcon: true,
      routeToGo: "FormasPagoYEntidadesCobranza",
      headers: [
        {
          text: "Nombre",
          align: "start",
          value: "descripcion"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "end"
        }
      ],
      configuracionesItems: [],
      expanded: [],

      searchIcon: enums.icons.SEARCH,
      search: "",
      isLoading: false,
      allowedActions: null,
      canEdit: false,
      canDelete: false,
      canCreate: false,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      titleDelete: "¿Eliminar registro?",
      showDeleteModal: false,
      plantillaProcesoSelected: null,
      plantillasItems: [],
      mpconfig: null,
      openModalEditConfigAdhesion: false,
      siroConfig: false
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setPlantillasProcesosTiposAdhesiones();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getPlantillasProcesosTiposAdhesiones:
        "configuracion/getPlantillasProcesosTiposAdhesiones",
      getProcesosAdhesionConfigs: "configuracion/getProcesosAdhesionConfigs",
      getProcesosAdhesionById: "configuracion/getProcesosAdhesionById",
      deleteProcesoAdhesionConfig: "configuracion/deleteProcesoAdhesionConfig",
      setAlert: "user/setAlert"
    }),
    async setPlantillasProcesosTiposAdhesiones() {
      const response = await this.getPlantillasProcesosTiposAdhesiones();
      this.plantillasItems = response;
    },
    async setProcesosAdhesionConfigs() {
      this.isLoading = true;
      switch (this.plantillaProcesoSelected) {
        case 1:
          this.configuracionesItems = await this.getProcesosAdhesionById(
            this.plantillaProcesoSelected
          );
          break;

        default:
          break;
      }
      this.isLoading = false;
    },
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .ELIMINAR_PROCESO_ADHESION_CONFIG:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_PROCESO_ADHESION_CONFIG:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .NUEVO_PROCESO_ADHESION_CONFIG:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    openModal(item) {
      this.openModalEditConfigAdhesion = true;
      this.procesoAdhesionId = item?.procesoAdhesionId;
    },
    closeAndReload() {
      this.openModalEditConfigAdhesion = false;
      this.procesoAdhesionId = null;

      this.setProcesosAdhesionConfigs();
    },
    deleteConfig(item) {
      this.showDeleteModal = true;
      switch (this.plantillaProcesoSelected) {
        case 1:
          this.idToDelete = item.procesoAdhesionId;
          break;
        default:
          break;
      }
    },
    async confirmDelete() {
      switch (this.plantillaProcesoSelected) {
        case 1:
          const response = await this.deleteProcesoAdhesionConfig(
            this.idToDelete
          );
          if (response.status === 200) {
            this.showDeleteModal = false;
            this.setAlert({ type: "success", message: "Eliminado con éxito." });
          }
          break;

        default:
          break;
      }
      this.setProcesosAdhesionConfigs();
    }
  }
};
</script>

<style>
.comboTipoAdhesion pt-0 {
  font-size: 1rem;
}
</style>
